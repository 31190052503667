import './App.css';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { Suspense, lazy } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { primaryAccent } from './constants/theme';

const Home = lazy(() => import('./pages/Home/Home'))

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Suspense fallback={<div className='main-loader-card'><ThreeDots
          height="50"
          width="50"
          radius="9"
          color={primaryAccent}
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        /></div>}>
          <Home />
        </Suspense>
      </Route>
    </Switch>
  );
}

export default App;
